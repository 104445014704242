import {
  shaderMaterial,
  Text,
  Html,
  ContactShadows,
  Float,
  Environment,
  useGLTF,
  PresentationControls,
} from "@react-three/drei";
import { useState, useEffect, useRef } from "react";
import { useFrame, extend } from "@react-three/fiber";
import { gsap } from "gsap";
import { BlendFunction } from "postprocessing";
import * as THREE from "three";

// Define your custom shader material
const CustomShaderMaterial = shaderMaterial(
  // Uniforms
  { time: 0, color: new THREE.Color() },
  // Vertex Shader
  `
     uniform float time;
     varying vec2 vUv;

     mat2 get2dRotateMatrix(float _angle)
     {
         return mat2(cos(_angle), - sin(_angle), sin(_angle), cos(_angle));
     }
     void main() {
       vUv = uv;
       vec4 modelPosition = modelMatrix * vec4(position, 1.0);

       float angle = sin((position.z * 3.0 + time)  * 1.3);
       mat2 rotateMatrix = get2dRotateMatrix(angle);

       modelPosition.xy *= rotateMatrix;
       vec4 viewPosition = viewMatrix * modelPosition;

       vec4 projectedPosition = projectionMatrix * viewPosition;
       gl_Position = projectedPosition;
     }`,
  // Fragment Shader
  `uniform float time;
     uniform vec3 color;
     varying vec2 vUv;
     void main() {
       gl_FragColor = vec4(color, 1.0);
     }`
);

extend({ CustomShaderMaterial });

export default function Experience() {
  const drunkRef = useRef();
  const drunkProps = {
    frequency: 2,
    amplitude: 0.2,
  };

  const banana = useGLTF("./banana.gltf");
  const wonkyBanana = useGLTF("./banana.gltf");
  const baguette = useGLTF("./baguette.gltf");
  const bananaRef = useRef();

  // wonkyBanana.scene.traverse((child) => {
  // if (child.isMesh) {
  // const originalMaterial = child.material;
  //
  //Create an instance of your custom shader material
  // const customMaterial = new CustomShaderMaterial({
  // color: originalMaterial.color, // Pass any textures from the original material
  // });
  //
  //Apply custom material to each mesh
  // child.material = customMaterial;
  // }
  // });

  useFrame((state, delta) => {
    bananaRef.current.rotation.y += delta * 0.2;

    // wonkyBanana.scene.traverse((child) => {
    // if (child.material && child.material.uniforms) {
    //   child.material.uniforms.time.value = state.clock.elapsedTime;
    // }
    //   });
  });

  const [count, setCount] = useState(
    parseInt(localStorage.getItem("count") ?? 0)
  );

  useEffect(() => {
    return () => {
      localStorage.removeItem("count");
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("count", count);
    // Animate banana.
    const shakeIntensity = {
      x: 0.1,
      y: 0.1,
      z: 0.1,
    };

    if (count % 10) {
      // Make the Banana shake.
      gsap.to(bananaRef.current.position, {
        duration: 0.05,
        repeat: 5,
        yoyo: true,
        ease: "power1.inOut",
        x: `+=${Math.random() * shakeIntensity.x - shakeIntensity.x / 2}`,
        y: `+=${Math.random() * shakeIntensity.y - shakeIntensity.y / 2}`,
        z: `+=${Math.random() * shakeIntensity.z - shakeIntensity.z / 2}`,
        onComplete: () => {
          //Return to the original position.
          gsap.to(bananaRef.current.position, {
            duration: 0.01,
            x: 0,
            y: 0,
            z: 0,
            ease: "power2.inOut",
          });
        },
      });
    } else {
      const fullRotation = 2 * Math.PI;

      // Animate the flip.
      gsap.to(bananaRef.current.rotation, {
        duration: 1,
        ease: "power2.inOut",
        x: `+=${fullRotation}`,
        onComplete: () => {
          // Reset the rotation to its normalized value to avoid overflow.
          bananaRef.current.rotation.x %= fullRotation;
        },
      });
    }
  }, [count]);

  const eventHandler = (event) => {
    setCount(count + 1);
  };

  return (
    <>
      <Environment preset="city" />
      <color args={["#637f99"]} attach="background" />

      <PresentationControls
        global
        rotation={[0.13, 0.1, 0]}
        polar={[-0.4, 0.2]}
        azimuth={[-1, 0.75]}
        config={{ mass: 2, tension: 400 }}
        snap={{ mass: 4, tension: 400 }}
      >
        <Float rotationIntensity={0.4}>
          <primitive
            ref={bananaRef}
            object={count > 1000 ? baguette.scene : banana.scene}
            position-y={0}
            scale={[3, 3, 3]}
            onClick={eventHandler}
          />

          <Html
            // transform
            wrapperClass="label"
            center
            distanceFactor={8}
            position={[0, -0.5, 0]}
            rotation-x={-0.256}
          >
            {count}
          </Html>

          <rectAreaLight
            width={2.5}
            height={1.65}
            intensity={65}
            color={"#aeddeb"}
            rotation={[-0.1, Math.PI, 0]}
            position={[0, 0.55, -1.15]}
          />

          <Text
            font="./bangers-v20-latin-regular.woff"
            fontSize={0.5}
            position={[2, 0.8, -1.5]}
            rotation-y={-1.25}
            maxWidth={4}
          >
            Website under Construction ...
          </Text>

          <Text
            font="./bangers-v20-latin-regular.woff"
            fontSize={0.2}
            position={[-0.7, -1.3, 0.5]}
            rotation-y={-0.55}
            rotation-x={-0.55}
            maxWidth={1}
            color="#f0f0f0"
          >
            But you can click on the banana!
          </Text>
        </Float>
      </PresentationControls>

      <ContactShadows position-y={-1.4} opacity={0.4} scale={5} blur={2.4} />
    </>
  );
}
